<template>
  <div class="news">
    <div class="news__title-container">
      <span>首页 > </span>
      <span>新闻资讯</span>
    </div>
	
	<template v-if="this.currentPage1 == 1">
		<div class="news__container">
		  <img src="./6/1.png" alt="">
		  <div class="news__content">
		    <router-link to="/news6">
		      <div class="news__content-title">谈谈新能源连接器市场中连接器的发展趋势</div>
		    </router-link>
		    <div class="news__content-answer">
		      伴随着时代的进步，电子技术的快速发展，电子元器件中的连接器产品在汽车行业、医疗行业、智能家居行业以及微电子行业越来越受到人们的重视，因为这些电子产品都是和我们日常生活中息息相关。而如今的元器件越来越小，电路密度越来越高，传输速度越来越快这是电子产品……
		    </div>
		    <div class="news__content-time">2022-01-15 15:47</div>
		  </div>
		</div>
		<div class="news__container">
		  <img src="./5/1.png" alt="">
		  <div class="news__content">
		    <router-link to="/news5">
		      <div class="news__content-title">PCB技术发展的两大趋势</div>
		    </router-link>
		    <div class="news__content-answer">
		      终端设备尺寸不断减小以满足用户对便携性的需求，但板级功能日趋复杂，而且高速信号应用越来越多，以致PCB空间越来越拥挤，上述电子产品多个发展方向都需要PCB小型化。缩小PCB尺寸，或者说提高PCB“集成度”的方法，通常可以细分为如下三种：增加层……
		    </div>
		    <div class="news__content-time">2021-12-31 13:32</div>
		  </div>
		</div>
		<div class="news__container">
		  <img src="./4.png" alt="">
		  <div class="news__content">
		    <router-link to="/news4">
		      <div class="news__content-title">什么是PCBA代工代料，PCBA代工代料有哪些类型</div>
		    </router-link>
		    <div class="news__content-answer">
		      1、什么是PCBA代工代料？狭义的PCBA代工代料指提供PCB板、电子元器件代购及PCBA加工的服务。广义的PCBA代工代料指提供从电子方案设计、电子产品开发到物料代购、样机打样测试、后期批量加工生产的一整套PCBA加工服务。2、PCBA代工代料有……
		    </div>
		    <div class="news__content-time">2021-01-22 17:47</div>
		  </div>
		</div>
		<div class="news__container">
		  <img src="./3.png" alt="">
		  <div class="news__content">
		    <router-link to="/news3">
		      <div class="news__content-title">SMT对于电子行业的发展做出了什么贡献</div>
		    </router-link>
		    <div class="news__content-answer">
		      随着21世纪的高科技发展，电子行业也是发展*快的一个行业，电子元件的微型化，这时要求SMT设备也就要高速研发微型化的SMT设备。SMT设备从狭意上讲，是将片式元器件贴装到PCB上，经过整体加热实现电子元器件互联，但从广意来讲，它包含片式元……
		    </div>
		    <div class="news__content-time">2021-01-22 15:32</div>
		  </div>
		</div>
		<div class="news__container">
		  <img src="./2.png" alt="">
		  <div class="news__content">
		    <router-link to="/news2">
		      <div class="news__content-title">SMT贴片检验有哪些标准</div>
		    </router-link>
		    <div class="news__content-answer">
		      SMT贴片检验这一步骤，可以规范SMT加工的工艺质量要求，以确保产品品质符合要求。下面一起来看看SMT贴片检验有哪些标准？一、SMT贴片锡膏工艺　1、PCB板上印刷的喷锡的位置与焊盘居中，无明显的偏移，不可影响SMT元器件的粘贴与上锡效果……
		    </div>
		    <div class="news__content-time">2021-01-22 13:38</div>
		  </div>
		</div>
	</template>
	<!-- 第二页 -->
	<template v-else-if="this.currentPage1 == 2">
		<div class="news__container news__container-first">
		  <img src="./1.png" alt="">
		  <div class="news__content">
		    <router-link to="/news1">
		      <div class="news__content-title">PCBA测试组装内容及工艺注意事项有哪些</div>
		    </router-link>
		    <div class="news__content-answer">
		      为了保证产品的稳定性、精准度，避免因为组装工艺、操作方法等导致产品不必要的返修，pcba厂商会采用PCBA测试组装的方式来解决。不过，业内人士也强调，PCBA测试组装并不是万能的，在实际操作过程中，也有很多需要注意的地方。究竟是哪些……
		    </div>
		    <div class="news__content-time">2021-01-22 10:52</div>
		  </div>
		</div>
	</template>
	<!-- 分页 -->
    <el-pagination
        background
        layout="prev, pager, next, jumper"
        :total="20"
		@current-change="handleCurrentChange"
        style="text-align: right;margin-top: 20px">
    </el-pagination>
  </div>
</template>

<script>
export default {
	data (){
		return {
			currentPage1: 1,
		}
	},
	methods:{
		handleCurrentChange(val){
			this.currentPage1 = val
		}
	}
}
</script>

<style lang="scss">
@import "@/styles/common.scss";

.news {
  padding: 40px gap();
  background-color: #fff;

}

.news__title-container {
  font-size: 14px;
  margin-bottom: 20px;

  span:nth-of-type(2) {
    color: #a6a6a6;
  }
}

.news__container {
  display: flex;
  padding-bottom: 40px;
  padding-top: 40px;
  border-bottom: 1px solid #D4D4D4;

  img {
    margin-right: 24px;
  }

  .news__content {
    display: flex;
    flex-direction: column;

    .news__content-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
	    color: #191919;
    }

    .news__content-answer {
      font-size: 16px;
      color: #494949;
    }

    .news__content-time {
      font-size: 12px;
      color: #A6A6A6;
      align-items: flex-end;
      margin-top: 50px;
    }
  }
}

.news__container-first{
  padding-top: 0;
}
</style>
